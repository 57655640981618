import React from "react";
import styles from "./Styles/ServicesBox.module.css";

import serviceOnce from "../assets/img/money.png";
import servicetwo from "../assets/img/get.jpg";
import servicethree from "../assets/img/DFS_CA_Marketing_2.jpg";
import dealer from "../assets/img/slider/BecomeADealer.jpeg";
import { PREVIEW } from "../project-config";
import { Link } from "gatsby";

const ServicesBox = () => {
  return (
    <div className="caro">
      <div className={styles.serviceContainer}>
        <div class="service-item-left servicesdata">
          <div className="serviceInfo">
            <img src={serviceOnce} alt="Save time and money 24/7" />
          </div>
          <h2>Save time and money 24/7.</h2>
          <h3>It’s Simple with our Site:</h3>
          <ul>
            <li>Offer cheques, forms, envelopes and more</li>
            <li>Fast quotes, orders and information</li>
            <li>Earn margins of 15-40%</li>
            <li>Speedy 3-6 day delivery on most products</li>
          </ul>
          <div className="leM">
            <Link to="/products/">
              Click to Learn More <i className="im im-icon-Arrow-OutRight"></i>
            </Link>
          </div>
        </div>
        <div class="service-item-right servicesdata">
          <div className="serviceInfo">
            <img src={servicetwo} alt="Get expert support" />
          </div>
          <h2>Get expert support.</h2>
          <h3>Reps with industry experience are ready to help you.</h3>
          <ul>
            <li>Award-winning service from start to finish</li>
            <li>Answers to help you get orders</li>
            <li>Email or call for fast assistance</li>
          </ul>
          <div className="leM">
            <Link to="/contact/">
              Click to Learn More <i className="im im-icon-Arrow-OutRight"></i>
            </Link>
          </div>
        </div>
        <div class="service-item-left servicesdata">
          <div className="serviceInfo">
            <img src={servicethree} alt="Get marketing support" />
          </div>
          <h2>Get marketing support.</h2>
          <h3>Win more business and steadily boost sales.</h3>
          <ul>
            <li>
              Close deals with professional print and digital marketing tools.
            </li>
            <li>Earn margins of 15-40%</li>
            <li>Speedy 3-6 day delivery on most products</li>
          </ul>
          <div className="leM">
            <Link to="/sales-resources/sales-material/">
              Click to Learn More <i className="im im-icon-Arrow-OutRight"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.becomeADealerWrapper}>
        <div>
          <img
            className={styles.dealerImg}
            src={dealer}
            alt="Man holding a tablet"
          />
        </div>

        <div className={styles.textWrapper}>
          <h2>Interested in becoming a DFS Dealer?</h2>
          <ul>
            <li>Earn generous margins on all sales</li>
            <li>Win more business and profit</li>
          </ul>
          <div className={styles.callToActionWrapper}>
            <a href={`${PREVIEW}/register.html?vid=20090722001&mt=1&ml=en`}>
              <button className={styles.ctaBtn}>Apply Now</button>
            </a>
            <div className={styles.textCta}>
              <p>
                Or <a href="/why-choose-dfs/">click here to learn more</a> about
                DFS
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="service-Dealer1">
        <div
          style={{
            textAlign: "center"
          }}
        >
          <div>
            <Link to="/covid">
              <img
                src={safety}
                width="100%"
                alt="Safety measures at our plant"
              />
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ServicesBox;
