import React, { useState, useEffect, useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "gatsby";
import { PREVIEW, PROJECT_LINK } from "../project-config.js";
import { I18nContext } from "../i18n/index";
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";

import "./Styles/header.css";
import "./Styles/NavMenu.css";

export default function NavMenu({ data }) {
  const { langCode, translate } = useContext(I18nContext);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = () => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
  };

  const renderNavLinks = () => {
    return (
      navCatsState.childs &&
      navCatsState.childs.map(cat => {
        let catName = cat.description;
        let arrURL = cat.URL.split(`/`);
        let lastURL = arrURL[arrURL.length - 1];
        let className = `navlink-${lastURL}`;
        let tempUrl = cat.URL.replace("shop/", "");
        let langTemp = tempUrl.includes(langCode) ? "" : `${langCode}/`;
        return (
          <div key={cat.cid}>
            {cat.childs.length > 0 && cat.description == "Signature Packaging" && (
              <div
                id={`HomeCategory-${cat.description
                  .replace(" ", "")
                  .replace(" ", "")
                  .replace("&amp;", "")}`}
                style={{ display: "flex", margin: "40px 0px" }}
              >
                {cat.childs.map(subcat => (
                  <div
                    key={subcat.cid}
                    className={`HomeCateg cate-${subcat.description
                      .replace(" ", "")
                      .replace(" ", "")
                      .replace("&amp;", "")}`}
                  >
                    {subcat.description == "Gift Cards" ? (
                      <Link
                        to={PREVIEW + "/" + subcat.URL}
                        onClick={() =>
                          handleCategoryChange(
                            subcat.cid,
                            subcat.description,
                            [
                              [catName, cat.cid],
                              [subcat.description, subcat.cid]
                            ],
                            subcat.URL
                          )
                        }
                      >
                        <img
                          className={`HomeC-${subcat.description}`}
                          src={`${PROJECT_LINK}/store/${subcat.image}`}
                        />
                        <p>{subcat.description}</p>
                      </Link>
                    ) : (
                      <Link
                        to={PREVIEW + "/" + subcat.URL}
                        onClick={() =>
                          handleCategoryChange(
                            subcat.cid,
                            subcat.description,
                            [
                              [catName, cat.cid],
                              [subcat.description, subcat.cid]
                            ],
                            subcat.URL
                          )
                        }
                      >
                        <img
                          className={`HomeC-${subcat.description}`}
                          src={`${PROJECT_LINK}/store/${subcat.image}`}
                          style={{
                            width: "100%"
                          }}
                        />
                        <p>{subcat.description}</p>
                      </Link>
                    )}
                    <ul>
                      {subcat.childs.map(subsubcat => (
                        <li key={subsubcat.cid} className="HomeSubChilds">
                          <div className={subcat.description}>
                            <Link
                              to={
                                PREVIEW +
                                "/shop/" +
                                subsubcat.URL.replace("shop/", "")
                              }
                              onClick={() =>
                                handleCategoryChange(
                                  subsubcat.cid,
                                  subsubcat.name,
                                  [
                                    [catName, cat.cid],
                                    [subcat.name, subcat.cid],
                                    [subsubcat.name, subsubcat.cid]
                                  ],
                                  subsubcat.URL.replace("shop/", "")
                                )
                              }
                            >
                              {/* <img
                                              className="catImgss"
                                              src={`${PROJECT_LINK}/store/${subsubcat.thumbnail}`}
                                            /> */}
                              <p>{subsubcat.description}</p>
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })
    );
  };

  return (
    <>
      <h1 className="browseCat">Experience the DFS Difference</h1>
      {renderNavLinks()}
    </>
  );
}
