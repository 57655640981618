import React from "react";

// core components
import Slider from "./Slider";
import StaticContent from "../components/AC-StaticPages/StaticContent.jsx";
import ServicesBox from "./ServicesBox";
import FeaturedPromotions from "./FeaturedPromotions";

function Main(props) {
  if (props.component === "staticContent") {
    return (
      <React.Fragment>
        <div id="bd">
          <StaticContent />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div id="bd">
          <div className="item">
            <div style={{ padding: "unset" }}>
              <div id="slide">
                <div className="slideshow-container">
                  <Slider />
                </div>
              </div>
            </div>
            <FeaturedPromotions />
            <ServicesBox />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
