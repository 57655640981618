import React, { useState, useEffect, useCallback } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import styles from "./Styles/Slider.module.css";
// import slider3 from "../assets/img/slider/dfsca-homepage-image.jpg";
import banner1 from "../assets/img/banner_1.jpg";
import banner2 from "../assets/img/banner_2.jpg";
import { PREVIEW, VID } from "../project-config";
import { shallowEqual, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "gatsby";
// import sdfsdf from "../header/SearchHelper";

const Slider = () => {
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const [bannerElements, setBannerElements] = useState([1]);

  const securityToken = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );

  const firstBannerElement = (
    <div className={styles.bannerWrapper}>
      <div className={styles.textWrapper}>
        <h1>Experience the DFS Difference</h1>
        <p>
          You need the latest products, export support, and top-notch service to
          meet customer demands. at DFS you'll find everything you need to
          succeed.
        </p>

        <button
          onClick={() => {
            if (typeof window !== "undefined") {
              window.location = `${PREVIEW}/register.html?vid=${VID}&mt=1&ml=${languageState}`;
            }
          }}
        >
          CREATE AN ACCOUNT
        </button>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src={banner2}
          className="w-full"
          alt="DFS Hero: Man smiling and shaking hands"
        ></img>
      </div>
    </div>
  );

  const secondBannerElement = (
    <div className={styles.bannerWrapper}>
      <div className={styles.textWrapper}>
        <h1>Market Like A Pro</h1>
        <p>Access ready-to-go banners, email, templates and more!</p>

        <Link
          className={styles.btn}
          role="button"
          to={"/sales-resources/sales-material/"}
        >
          GET STARTED
        </Link>
      </div>
      <div className={styles.imageWrapper}>
        <img src={banner1} className="w-full" alt="DFS Hero: Man smiling"></img>
      </div>
    </div>
  );

  console.log("bannerElements", bannerElements);

  useEffect(() => {
    if (securityToken) {
      setBannerElements([1, 2]);
    } else {
      if (bannerElements.length !== 1) setBannerElements([1]);
    }
  }, [securityToken]);

  const responsive = {
    mobile: {
      breakpoint: { max: 9999, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const CarouselElement = useCallback(() => {
    console.log("bannerElements2", bannerElements.length);

    return (
      <Carousel
        ssr={true}
        showDots={bannerElements.length > 1 ? true : false}
        swipeable={false}
        infinite={true}
        autoPlay={bannerElements.length > 1 ? true : false}
        autoPlaySpeed={5000}
        responsive={responsive}
        arrows={false}
      >
        {bannerElements.map((item, index) => {
          if (item === 1) {
            return firstBannerElement;
          }
          if (item === 2) {
            return secondBannerElement;
          }
        })}
      </Carousel>
    );
  }, [bannerElements]);

  return <CarouselElement />;

  // return (
  //   <>
  //     {/* <div id="homeBanner" className={styles.bannerWrapper}> */}
  //     <div className={styles.bannerWrapper}>
  //       {/*      <a
  //         href="https://video-10.wistia.com/medias/1bvdc19av9"
  //         target="_blank"
  //         className="flex flex-col text-white"
  //       >
  //         <img
  //           src={slider3}
  //           className="w-full"
  //           alt="DFS Hero: Man smiling and holding business card"
  //         />
  //         <span className={styles.textWhite}>(opens in new window)</span>
  //       </a> */}
  //       <div className={styles.textWrapper}>
  //         <h1>Experience the DFS Difference</h1>
  //         <p>
  //           You need the latest products, export support, and top-notch service
  //           to meet customer demands. at DFS you'll find everything you need to
  //           succeed.
  //         </p>

  //         <button
  //           onClick={() => {
  //             if (typeof window !== "undefined") {
  //               window.location = `${PREVIEW}/register.html?vid=${VID}&mt=1&ml=${languageState}`;
  //             }
  //           }}
  //         >
  //           CREATE AN ACCOUNT
  //         </button>
  //       </div>
  //       <div className={styles.imageWrapper}>
  //         <img
  //           src={banner2}
  //           className="w-full"
  //           alt="DFS Hero: Man smiling and shaking hands"
  //         ></img>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default Slider;
